import React, { ReactNode, useEffect } from "react";
import { obsoleteWarning } from "@telia/b2x-obsolete-component";

import styles from "./b2x-breadcrumbs-item.module.scss";

type Props = {
  children: ReactNode;
  current?: boolean;
  datatestId?: string;
  darkBackground?: boolean;
  ariaLabel?: string;
};

export const B2xBreadcrumbsItem = ({
  children,
  current,
  datatestId,
  darkBackground = false,
  ariaLabel,
}: Props) => {
  useEffect(() => {
    obsoleteWarning("b2x-breadcrumbs-item", "components-breadcrumbs--docs");
  }, []);

  const rootClassName = styles["breadcrumbs-item"];
  const backgroundClassName = darkBackground ? styles[`breadcrumbs-item--dark-background`] : "";
  const currentClassName = current ? styles[`breadcrumbs-item--current`] : "";

  const renderDivider = () => (
    <span className={styles["breadcrumbs-item__item-divider"]} aria-hidden>
      /
    </span>
  );

  return (
    <li
      aria-current={current ? "page" : false}
      className={`${rootClassName} ${currentClassName} ${backgroundClassName}`}
      data-testid={datatestId}
      aria-label={ariaLabel}
    >
      {children}
      {!current && renderDivider()}
    </li>
  );
};
